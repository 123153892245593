@use 'styles' as theme;
.root {
  --textColor: #{theme.$colorWhite};
  --bgColor: #{theme.$colorBlack};

  @include theme.boxShadow;

  color: var(--textColor);
  background-color: var(--bgColor);
  border-radius: theme.$borderRadiusXS;
  padding: theme.size(2);
  text-align: center;

  &.lightBlue {
    --bgColor: #{theme.$colorBlue200};
    --textColor: #{theme.$colorBlack};
  }

  &.yellow {
    --bgColor: #{theme.$colorYellow};
    --textColor: #{theme.$colorBlack};
  }

  &.blue {
    --bgColor: #{theme.$colorBlue500};
    --textColor: #{theme.$colorWhite};
  }
}

.divider {
  background-color: var(--textColor);
  width: theme.size(12.5);
  height: 1px;
}
