@use 'styles' as theme;

.root {
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: theme.size(132.5);

  &.light {
    --fillColor: #{theme.$colorBlue300};
    --borderColor: #{theme.$colorWhite};
    --circleColor: #{theme.$colorBlue500};
    --circleBorderColor: #{theme.$colorBlue};

    .filled {
      --fillColor: #{theme.$colorBlue};
    }
  }

  &.dark {
    --fillColor: #{theme.$colorBlue900};
    --borderColor: #{theme.$colorBlue};
    --circleColor: #{theme.$colorBlue50};
    --circleBorderColor: transparent;

    .filled {
      --fillColor: #{theme.$colorYellow};
    }
  }
}

.map {
  width: 100%;
  color: transparent;
}
