@use 'styles' as theme;

.root {
  width: fit-content;
  max-width: 100%;
  overflow-x: auto;

  @include theme.mediaTabletLandscapeUp {
    overflow-x: visible;
  }
}

.table {
  overflow: hidden;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: theme.$borderRadiusS;
  box-shadow: 0 0 0 1px theme.$colorGrey200;
  text-align: center;
  color: theme.$colorGrey900;
}

.head {
  color: theme.$colorGrey600;
}

.headCell {
  padding: theme.size(1 1.5);
  background: theme.$colorGrey50;
}

.head,
.cell {
  padding: theme.size(1.5);
  border: 1px solid theme.$colorGrey200;
  font-weight: inherit;
  vertical-align: top;
}

.text {
  margin: theme.size(2);
}
