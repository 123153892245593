@use 'styles' as theme;
@import '../../../styles/variables.module';
@import '../../../styles/mixins';

.root {
  @include bodyS;
  font-weight: unset;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  border-bottom: 1px solid theme.$colorGrey400;
}

.headCell {
  @include labelSLight;
  padding: theme.size(1);
  text-align: left;
  overflow-x: auto;
}

.head,
.cell {
  padding: theme.size(1.5 1);
  border-bottom: 1px solid theme.$colorGrey200;
  border-top: 1px solid theme.$colorGrey600;
  overflow-x: auto;
}
