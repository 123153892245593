@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 $containerSmallPadding;

  @include for-tablet-portrait-up {
    padding: 0 $containerLargePadding;
  }

  @include for-big-desktop-up {
    padding: 0 $containerSmallPadding;
  }
}

.padded {
  padding-top: 60px;
  padding-bottom: 60px;
}
