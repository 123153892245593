@use 'styles' as theme;

.root {
  border-radius: theme.$borderRadiusXS;
  overflow: hidden;
  display: flex;
}

.placeholder {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  background-color: theme.$colorGrey200;
}
