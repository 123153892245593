@use 'styles' as theme;

.wrapper {
  padding: theme.size(6 0 8);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(8 0 15);
  }
}

.policy {
  display: flex;
  align-items: stretch;
  order: var(--order);
}

.illustrationWrapper {
  order: 0;

  @include theme.mediaTabletLandscapeUp {
    order: -1;
  }
}

.illustration {
  display: flex;
  height: 100%;
  max-height: theme.size(42);
}
