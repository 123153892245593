@use 'styles' as theme;
.wrapper {
  @include theme.unstyledList;

  align-items: stretch;
  margin-bottom: theme.size(3);

  @include theme.mediaDesktopUp {
    margin-bottom: theme.size(5);
  }
}

.button {
  max-width: theme.size(41);
}

.pricingBlock {
  height: 100%;
  flex: 1;
}

.item {
  align-items: end;
  display: flex;
}
