@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

$height: 30px;

.root {
  position: relative;
  z-index: 1;
  line-height: $height;
  margin-bottom: -$height;
  background: theme.$colorGrey50;
  font-size: 14px;
  text-transform: uppercase;
}

.link {
  @include link(inherit);
}
