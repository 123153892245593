@use 'styles' as theme;
.markets {
  @include theme.flexRow($mainAxisAlign: center, $wrap: true);

  gap: theme.size(1.5);
}

.center {
  @include theme.flexRow($mainAxisAlign: center, $wrap: true);

  @include theme.mediaDesktopUp {
    text-align: center;
    grid-column: 2 / span 10;
  }
}

.left {
  text-align: left;
  grid-column: 1 / -1;

  @include theme.mediaDesktopUp {
    @include theme.flexRow($mainAxisAlign: flex-start, $wrap: true);
  }
}

.expandButton {
  @include theme.unstyledButton;
  @include theme.outlineOnFocus;
  @include theme.transition(color background-color, $focusOutline: true);

  &:hover {
    background-color: theme.$colorBlack;
    color: theme.$colorWhite;
  }
}

.flagWrapper {
  @include theme.boxShadow;

  background-color: theme.$colorWhite;
  border-radius: theme.$borderRadiusPill;
  padding: theme.size(0.25 2 0.25 1);
}
