@use 'styles' as theme;

.root {
  @include theme.outlineOnFocus;

  border-radius: theme.$borderRadiusXL;
  display: flex;
}

.section {
  @include theme.transition(box-shadow);

  background-color: theme.$colorBlue50;
  border-radius: theme.$borderRadiusXL;
  padding: theme.size(4);

  &:hover {
    @include theme.innerBorder(2px, theme.$colorBlack);
  }

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(5 4);
  }
}

.button {
  margin-top: auto;
}
