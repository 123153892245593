@use 'styles' as theme;
.icon {
  @include theme.square(theme.size(10));
  color: transparent;

  @include theme.mediaDesktopUp {
    @include theme.square(theme.size(15));
  }
}

.title {
  @include theme.mediaDesktopUp {
    max-width: theme.size(126);
  }
}
