@use 'styles' as theme;
.root {
  background-color: theme.$colorWhite;
}

.plain {
  border-bottom: 1px solid theme.$colorGrey200;
}

.bordered {
  border: 1px solid theme.$colorGrey200;
  border-radius: theme.$borderRadiusXS;
}

.button {
  @include theme.unstyledButton;
  @include theme.flexRow(center, space-between);
  @include theme.outlineOnFocus;

  gap: theme.size(1);
  width: 100%;
  white-space: initial;
  text-align: left;

  &:active {
    transform: none;
  }

  .plain & {
    padding: theme.size(2 0);
  }

  .bordered & {
    padding: theme.size(2.5 2);
  }

  @include theme.mediaDesktopUp {
    padding: theme.size(3 0);
  }
}

.contentWrapper {
  overflow: hidden;
}

.content {
  padding-bottom: theme.size(3);

  .bordered & {
    padding: theme.size(0 2 3);
  }
}

.text {
  flex: 1;
}
