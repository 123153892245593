@use 'styles' as theme;
.column {
  flex: 1;
}

.desktopHeader {
  @include theme.hideUntilDesktop;
  margin-bottom: theme.size(6);
}

.mobileHeader {
  @include theme.hideFromDesktop;
  margin-bottom: theme.size(2);
}

.content {
  margin-bottom: theme.size(4);
}

.imageWrapper {
  position: relative;
  display: flex;
}

.image {
  margin: 0 auto;
  border-radius: theme.$borderRadiusL;
}

.notification {
  position: absolute;

  @include theme.mediaPhoneOnly {
    max-width: theme.size(30);
  }

  @include theme.mediaTabletPortraitUp {
    white-space: nowrap;
  }
}

.illustration {
  @include theme.hideUntilTablet;
  position: absolute;
  right: 0;
  z-index: 0;
  bottom: -2px;
  width: theme.size(17.5);
  transform: translateX(var(--xOffset));

  @include theme.mediaTabletPortraitUp {
    --xOffset: 40%;
    display: block;
  }

  @include theme.mediaDesktopUp {
    --xOffset: 40%;
  }

  @include theme.mediaDesktopBigUp {
    --xOffset: 65%;
  }
}

.womanNotification {
  composes: notification;
  top: theme.size(-7);
  right: theme.size(2);

  @include theme.mediaTabletPortraitUp {
    top: theme.size(3);
    left: auto;
    right: theme.size(-4);
  }
}

.manNotification {
  composes: notification;
  bottom: theme.size(6);
  left: theme.size(3);

  @include theme.mediaTabletPortraitUp {
    top: 53%;
    bottom: auto;
    left: theme.size(-4);
  }
}
