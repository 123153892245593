@use 'styles' as theme;
@import 'styles/variables.module';

.wrapper {
  padding: theme.size(0 0 8);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(7 0 15);
  }
}

.content {
  h2 {
    scroll-margin-top: calc($headerHeight + theme.size(2));
  }

  ol {
    li {
      list-style-type: none;
      margin: theme.size(2 0 2 2);
    }
  }

  p,
  li {
    text-align: justify;
  }

  th {
    padding: theme.size(1 1.5);
  }

  td {
    padding: theme.size(1.5);
  }

  td {
    p {
      text-align: left;
    }
  }
}

.backButtonWrapper {
  padding: theme.size(3 0);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(5 0);
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: theme.size(1);

  @include theme.mediaTabletLandscapeUp {
    gap: theme.size(2);
  }
}
