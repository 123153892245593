@use 'styles' as theme;
@import 'styles/variables.module';
@import 'styles/mixins';

.wrapper {
  align-items: center;
}

.loginLinkWrapper {
  --offsetFromHeader: #{theme.size(3)};
  height: min-content;
  position: absolute;
  inset: calc(#{$headerHeight} + var(--offsetFromHeader)) 0 0 0;
  text-align: right;

  @include theme.mediaTabletLandscapeUp {
    --offsetFromHeader: #{theme.size(4)};
  }
}

.loginLink {
  @include theme.outlineOnFocus;

  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.titleWrapper {
  text-align: center;

  @include theme.mediaDesktopUp {
    text-align: left;
  }
}

.stats {
  --offset: #{theme.size(6)};

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: theme.size(2);
  margin-bottom: var(--offset);

  @include theme.mediaDesktopUp {
    grid-template-columns: minmax(280px, 1fr) repeat(2, minmax(180px, 1fr));
  }
}

.imageWrapper {
  grid-column: 2;

  @include theme.mediaDesktopUp {
    grid-column: 1;
    grid-row: span 2;
  }
}

.statsCard {
  &:nth-child(2) {
    grid-column: 1;
    grid-row: 1;
  }

  &:nth-child(odd) {
    --offset: 0;
  }

  @include theme.mediaDesktopUp {
    &:nth-child(2) {
      grid-column: auto;
      grid-row: auto;
    }
  }

  &.hiddenOnMobile {
    @include theme.hideUntilDesktop;
  }
}

.divider {
  background-color: var(--textColor);
  width: theme.size(12.5);
  height: 1px;
}
