@use 'styles' as theme;
.step {
  flex: 1;
}

.illustration {
  @include theme.square(theme.size(15));
  color: transparent;
}

.gridSteps {
  @include theme.hideUntilDesktop;
}

.swipeableSteps {
  @include theme.hideFromDesktop;
}
