@use 'styles' as theme;
@import 'styles/variables.module';

.ul {
  @include theme.unstyledList;

  list-style-type: disc;
  padding-left: theme.size(1.75);
}

.h2 {
  margin-top: theme.size(5);
}

.h3,
.h4,
.ul,
.paragraph {
  margin-top: theme.size(3);
}

.h2,
.h3,
.h4 {
  scroll-margin-top: calc($headerHeight + theme.size(2));
}

.li {
  @include theme.textM;
}
