@use 'styles' as theme;
@import '../../styles/mixins';

$largeRatio: 1.8;
$regularSize: 50px;
$tightSize: 25px;

.root {
  position: relative;

  // This prevents sections which have a content leaking outside their bounds from creating horizontal scrollbars.
  // Do not remove unless you know what you're doing!
  overflow: hidden;
}

// padding

.regularTop {
  padding-top: $regularSize * $largeRatio;

  &.responsive {
    padding-top: $regularSize;

    @include for-tablet-portrait-up {
      padding-top: $regularSize * $largeRatio;
    }
  }
}

.regularBottom {
  padding-bottom: $regularSize * $largeRatio;

  &.responsive {
    padding-bottom: $regularSize;

    @include for-tablet-portrait-up {
      padding-bottom: $regularSize * $largeRatio;
    }
  }
}

.tightTop {
  padding-top: $tightSize * $largeRatio;

  &.responsive {
    padding-top: $tightSize;

    @include for-tablet-portrait-up {
      padding-top: $tightSize * $largeRatio;
    }
  }
}

.tightBottom {
  padding-bottom: $tightSize * $largeRatio;

  &.responsive {
    padding-bottom: $tightSize;

    @include for-tablet-portrait-up {
      padding-bottom: $tightSize * $largeRatio;
    }
  }
}
