@use 'styles' as theme;

.root {
  @include theme.flexCenter;
}

.image {
  display: block;
  height: 100%;
}
