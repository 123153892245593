@use 'styles' as theme;
.root {
  @include theme.boxShadow;

  position: relative;
  overflow: hidden;
  border-radius: theme.$borderRadiusXS;
  aspect-ratio: 173 / 203;

  @include theme.mediaTabletPortraitUp {
    aspect-ratio: auto;
    min-height: theme.size(50);
  }

  @include theme.mediaDesktopUp {
    min-height: theme.size(60);
    aspect-ratio: auto;
  }
}

.imageWrapper {
  @include theme.absoluteFill;
}

.image {
  object-fit: cover;

  @include theme.mediaTabletPortraitUp {
    object-position: 50% 40%;
  }

  @include theme.mediaDesktopUp {
    object-position: center;
  }
}
