@use 'styles' as theme;
.tooltip {
  border-radius: theme.size(0.625);
  background: rgba(theme.$colorWhite, 0.5);
  box-shadow: 1px 1px 2px rgba(theme.$colorBlack, 0.25);
  font-size: theme.size(1.125);
  opacity: 0.9;
  color: theme.$colorGrey900;
  padding: theme.size(0.125 0.25);
  border: none;
}
