@use 'styles' as theme;

.root {
  @include theme.boxShadow;

  border: 1px solid theme.$colorBlue200;
  border-radius: theme.$borderRadiusXS;
  background-color: theme.$colorWhite;
  display: flex;
  flex-direction: column;
  max-width: theme.size(50);
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  margin: 0 auto;

  @include theme.mediaDesktopUp {
    max-width: none;
  }

  &::before {
    @include theme.transition(box-shadow);
    @include theme.absoluteFill($offset: -1px);

    content: '';
    border-radius: theme.$borderRadiusXS;
    display: block;
  }

  &:hover::before {
    @include theme.innerBorder(3px, theme.$colorBlue);
  }
}

.selected {
  @include theme.innerBorder(theme.size(0.25), theme.$colorBlue);

  border-color: theme.$colorBlue;
}

.spaceTop {
  @include theme.mediaDesktopUp {
    margin-top: theme.size(6);
  }
}

.banner {
  @include theme.flexCenter;

  background-color: theme.$colorBlue;
  padding: theme.size(1);
}

.cardWrapper {
  height: 100%;
  position: relative;
  padding: theme.size(4 3);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(4);
  }
}

.input {
  @include theme.visuallyHidden;

  &:checked {
    ~ .cardWrapper {
      .checkbox {
        background-color: theme.$colorBlue;
        color: theme.$colorWhite;
      }
    }
  }
}

.checkbox {
  @include theme.square(theme.size(4));
  @include theme.transition(background-color color);
  @include theme.flexCenter;

  border-radius: theme.$borderRadiusXS;
  border: 1px solid theme.$colorBlue;
  box-sizing: content-box;
  color: transparent;
  flex-shrink: 0;
}

.fullPrice {
  text-decoration: line-through;
  color: theme.$colorRed;
  margin-left: theme.size(0.5);
}

.buyButtonWrapper {
  flex-grow: 1;
}

.divider {
  border-top: 1px solid theme.$colorGrey200;
  width: 100%;
  margin-top: calc(#{theme.size(1)} * var(--marginTop));
  margin-bottom: calc(#{theme.size(1)} * var(--marginBottom));
}

.labelWrapper {
  @include theme.mediaDesktopUp {
    position: absolute;
    padding-right: inherit;
  }
}

.contentWrapper {
  --labelHeight: #{theme.size(5)};

  @include theme.mediaDesktopUp {
    height: 100%;
    position: relative;
    padding-top: theme.size(4);
    margin-top: var(--labelHeight);
  }
}
