@use 'styles' as theme;
@import 'styles/variables.module';

.root {
  border-top: 1px solid theme.$colorGrey400;
  border-bottom: 1px solid theme.$colorGrey400;
  position: sticky;
  top: calc($headerHeight + theme.size(3));
  overflow: auto;
  max-height: 80vh;
  margin: theme.size(0 -2);
  padding: theme.size(2 2 2 2.5);

  @include theme.mediaTabletLandscapeUp {
    border-color: transparent;
    margin: 0;
    padding: theme.size(0 0 0 0.5);
  }
}

.list {
  @include theme.unstyledList;

  @include theme.mediaTabletLandscapeUp {
    border-right: 2px solid theme.$colorBlue100;
  }
}

.listItem {
  display: flex;
  padding: theme.size(0.5 1 0.5 0);
  width: 100%;
}

.active {
  color: theme.$colorBlue;

  @include theme.mediaTabletLandscapeUp {
    box-shadow: 2px 0 0 0 theme.$colorBlue;
  }
}

.link {
  @include theme.transition(color, $focusOutline: true);
  @include theme.outlineOnFocus;

  border-radius: theme.$borderRadiusM;

  &:hover {
    color: theme.$colorBlue;
  }
}
