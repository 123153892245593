@use 'styles' as theme;
@import '../../../styles/variables.module';
@import '../../../styles/mixins';

.root {
  font-size: 14px;

  > :first-child {
    font-size: 1.25em;
  }

  > [role='status'] {
    display: block;

    a {
      @include link;
    }
  }
}

.closeAction {
  @include unstyledButton;
  @include increaseTargetSize;
  @include transition(color);

  position: absolute;
  top: 6px;
  right: 6px;
  color: theme.$colorGrey600;

  &:hover {
    color: theme.$colorGrey900;
  }
}

.closeIcon {
  @include square(0.5em);
}
