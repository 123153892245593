@use 'styles' as theme;
.black {
  color: theme.$colorGrey900;
}

.white {
  color: theme.$colorWhite;
}

.text {
  flex: 1;
}
