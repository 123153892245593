@use 'styles' as theme;

.table {
  font-size: theme.size(1.25);
  word-break: break-word;
}

.cell {
  &:first-child,
  &:nth-child(2) {
    width: theme.size(12.5);

    @include theme.mediaTabletPortraitUp {
      width: theme.size(15);
    }
  }
}

.manageCookiesButton {
  margin-top: theme.size(1);
}
