@use 'styles' as theme;
@import '../../../styles/mixins';

.dot {
  @include increaseTargetSize;
  @include theme.unstyledButton;
  @include theme.circle(theme.size(1));
  @include theme.transition(background-color width border-radius, 500ms);

  border: 1px solid theme.$colorBlack;
}

.dotActive {
  width: theme.size(5);
  background-color: theme.$colorBlack;
  border-radius: theme.$borderRadiusXXS;
}

.dotLight {
  background-color: theme.$colorWhite;
  border-color: theme.$colorWhite;
}

.image {
  pointer-events: none;
  user-select: none;
}

.description {
  overflow: hidden;
}
