@use 'styles' as theme;
@import '../../styles/variables.module';

.root {
  position: relative;
  display: inline-block;
}

.block {
  display: block;
  text-align: center;
  font-size: 20px;
}

// type: regular

.line {
  @function delay($interval, $count, $index) {
    @return ($index * $interval) - ($interval * $count);
  }

  display: inline-block;
  width: 0.25em;
  height: 2em;
  margin: 0 0.125em;
  border-radius: 0.125em;
  background: theme.$colorBlue;
  vertical-align: top;
  animation: loadingLines 0.9s delay(0.2s, 3, 0) infinite #{cubic-bezier(
      0.85,
      0.25,
      0.37,
      0.85
    )};
  animation-fill-mode: both;

  &:nth-child(2),
  &:nth-child(4) {
    background: theme.$colorYellow;
    animation-delay: delay(0.2s, 3, 1);
  }
  &:nth-child(1),
  &:nth-child(5) {
    animation-delay: delay(0.2s, 3, 2);
  }
}

@keyframes loadingLines {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(1);
  }
}

// type: circle

.circle {
  width: 1em;
  height: 1em;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    display: block;
    width: 0.15em;
    height: 0.15em;
    margin: 0 auto;
    border-radius: 0.075em;

    background: currentColor;
    animation: loadingFadingCircle 1.2s infinite ease-in-out both;
  }
  &:nth-child(2) {
    transform: rotate(30deg);

    &::before {
      animation-delay: -1.1s;
    }
  }
  &:nth-child(3) {
    transform: rotate(60deg);

    &::before {
      animation-delay: -1s;
    }
  }
  &:nth-child(4) {
    transform: rotate(90deg);

    &::before {
      animation-delay: -0.9s;
    }
  }
  &:nth-child(5) {
    transform: rotate(120deg);

    &::before {
      animation-delay: -0.8s;
    }
  }
  &:nth-child(6) {
    transform: rotate(150deg);

    &::before {
      animation-delay: -0.7s;
    }
  }
  &:nth-child(7) {
    transform: rotate(180deg);

    &::before {
      animation-delay: -0.6s;
    }
  }
  &:nth-child(8) {
    transform: rotate(210deg);

    &::before {
      animation-delay: -0.5s;
    }
  }
  &:nth-child(9) {
    transform: rotate(240deg);

    &::before {
      animation-delay: -0.4s;
    }
  }
  &:nth-child(10) {
    transform: rotate(270deg);

    &::before {
      animation-delay: -0.3s;
    }
  }
  &:nth-child(11) {
    transform: rotate(300deg);

    &::before {
      animation-delay: -0.2s;
    }
  }
  &:nth-child(12) {
    transform: rotate(330deg);

    &::before {
      animation-delay: -0.1s;
    }
  }
}

@keyframes loadingFadingCircle {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
