@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include print {
    padding-top: 0 !important;
  }
}

.top {
  position: fixed;
  z-index: $zIndexHeader;
  top: 0;
  right: 0;
  left: 0;

  @include print {
    position: static;
  }
}

.footer {
  flex-shrink: 0;
}
