@use 'styles' as theme;
.root {
  --size: #{theme.size(14)};
  --gap: #{theme.size(8)};
  --duration: 20s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));

  width: 100%;
  box-sizing: border-box;
  padding-top: #{theme.size(2)};
  padding-bottom: #{theme.size(2)};

  &.fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

.logosGroup {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: var(--gap);
  min-width: 100%;
  justify-content: center;

  &.animated {
    justify-content: space-around;
    animation: scroll-x var(--duration) linear infinite;
  }

  @keyframes scroll-x {
    from {
      transform: translateX(var(--scroll-start));
    }
    to {
      transform: translateX(var(--scroll-end));
    }
  }
}

.wrapper {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

  &.withFade {
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      rgba(theme.$colorBlack, 0),
      rgba(theme.$colorBlack, 1) 20%,
      rgba(theme.$colorBlack, 1) 80%,
      rgba(theme.$colorBlack, 0)
    );
  }
}

.imageWrapper {
  display: grid;
  place-items: center;
  width: var(--size);
  aspect-ratio: 16/9;
  user-select: none;
  pointer-events: none;
}
