@use 'styles' as theme;
@import '../../styles/mixins';

.root {
  @include theme.textM;

  color: theme.$colorGrey900;

  a {
    @include link;
  }

  h1 {
    @include theme.headingL;
  }

  h2 {
    @include theme.headingM;

    margin-top: theme.size(3);
  }

  h3 {
    @include theme.headingS;

    margin-top: theme.size(3);
  }

  h4,
  h5 {
    @include theme.headingXS;
  }

  p {
    margin: theme.size(2 0);
  }

  ul,
  ol {
    padding: 0;
    margin: theme.size(2 0 0);
    list-style: inside;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    @include theme.mediaPhoneOnly {
      @include theme.textXS;
    }
  }
  th,
  td {
    padding: theme.size(0.5 1);
    border: 1px solid theme.$colorGrey400;
    text-align: center;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;

    &[alt$=' >'] {
      float: right;
      width: 40%;
      margin: theme.size(0 0 1 2);
    }

    &[alt$=' <'] {
      float: left;
      width: 40%;
      margin: theme.size(0 2 1 0);
    }
  }
}

.centered {
  text-align: center;
}
