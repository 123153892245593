@use 'styles' as theme;
@import '../../../styles/mixins';

.container {
  @include colouredContainer(theme.$colorBlue200);
  display: grid;
  gap: theme.size(6);

  & {
    padding-bottom: 0;
  }

  @include theme.mediaTabletLandscapeUp {
    margin-bottom: theme.size(8);
  }

  @include theme.mediaDesktopUp {
    @include sectionVerticalPadding('bottom', 'small');
  }
}

.link {
  text-decoration: underline;

  &:hover,
  &:focus-within {
    text-decoration: none;
  }
}

.illustration {
  height: theme.size(40);

  @include theme.mediaDesktopUp {
    position: absolute;
    bottom: -1px;
    right: 10%;
  }
}
